export default {
    methods: {
        formataCpf(value){
            if(value == '')
                return '';

            value = value.toString();    

            var mask = value.slice().replace(/[^0-9]/g, '').slice(0,11);

            if(mask.length == 11)
                return mask.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')

            var result = '';

            for(let i = 0;i < mask.length; i++){
                result += mask[i];
                
                if([3,6].indexOf(i+1) !== -1)
                    result += '.';

                if((1+i) == 9)
                    result += '-'
            }

            return result;
        }
    }
}

const utilities = {
    clearObject(object){
        for(let key in object)
            object[key] = null;
    }
}

function toFormDate(data){
    let date = (data != "") ? new Date(data) : new Date();

    if(date == 'Invalid Date')
        return '';

    let day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();

    let month = date.getMonth() < 9 ? "0"+(date.getMonth()+1) : date.getMonth()+1;

  
    return `${day}/${month}/${date.getFullYear()}`;
}

function toFormTime(data){
    let date = (data != "") ? new Date(data) : new Date();

    if(date == 'Invalid Date')
        return '';
    
        let hours = date.getHours() < 10 ? "0"+date.getHours() : date.getHours();
    
        let minutes = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();

    return `${hours}:${minutes}`;
}

function toFormDateTime(data){
    let date = (data != "") ? new Date(data) : new Date();

    if(date == 'Invalid Date')
        return '';

    let day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();

    let month = date.getMonth() < 9 ? "0"+(date.getMonth()+1) : date.getMonth()+1;

    let hours = date.getHours() < 10 ? "0"+date.getHours() : date.getHours();

    let minutes = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();

  
    return `${day}/${month}/${date.getFullYear()} ${hours}:${minutes}`;
}

export { toFormDate, toFormTime, toFormDateTime, utilities }

