<template>
    <div>
        <div class="mt-lg-5 w-100 d-inline-block">
            <div class="m-3 bg-light rounded mt-4 p-3 text-center">
                <cota-item v-for="cota in cotas" @showPeriods="showPeriod(cota)" :key="cota.title" :cota="cota" />
            </div>
        </div>
        <div class="modal fade" ref="cota_periodos">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ inModal.title }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body bg-light">
                        <div class="p-2 pt-4 rounded">
                            <CotaPeriodo v-show="!inModal.only || (inModal.only && inModal.only == cp.id)" v-for="cp in inModal.period" :key="cp.id" :periodo="cp" />
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import http from '@/helpers/http';
import CotaItem from '@/components/Cota';
import CotaPeriodo from '@/components/CotaPeriodo';
import { events } from '@/helpers/emitter';
import  Modal  from 'bootstrap/js/dist/modal';

export default {
    name: 'CotasComponent',
    components: { CotaItem, CotaPeriodo },
    data(){
        return {
            cotas: [],
            modal: null,
            inModal: {
                only: null
            }
        }
    },
    methods:{
        showPeriod(cota){
            console.log('showPeriod chamado', cota);
            this.inModal = cota

            this.inModal.only = cota.idCotaPeriodo;

            this.modal.show();
        }
    },
    mounted(){
        this.modal = new Modal(this.$refs['cota_periodos']);

        http.get("cotas.php").then( e => {
            for(let key in e.data){
                this.cotas.push(e.data[key]);
            }
        });

        events.$on('ModalCotas.show', cota => {
            console.log('modal  cotas ativado ',cota);
            //adiciona a cota na exibição da modal
            this.inModal = this.cotas.filter( c => {
                return c.title == cota.title
            }).shift();

            console.log(this.inModal);

            this.inModal.only = cota.idCotaPeriodo;

            this.modal.show();
        })
    },
    unmounted(){
        events.$off('ModalCotas.show');
    }
}
</script>
