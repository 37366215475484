<template>
    <div class="position-relative overflow-hidden mb-4 shadow rounded">
            <div class="actions position-absolute w-100 h-100 start-0 top-0 d-flex justify-content-end align-items-center">
                <i @click="doRemove" class="bi bi-trash3-fill text-white m-3 fs-4 pointer"></i>    
            </div>
            <div @click="swipe" class="card cota-periodo border-0 bg-white p-1 px-3 position-relative" :class="{ 'swipe-left-in': swiped }">
                <div class="d-flex align-items-center justify-content-between fw-light mt-3">   
                    <label class="bg-light border-0 text-center px-2 rounded"> 
                        <label class="d-block font-13 fst-italic fw-light">inicio</label>
                        {{ toFormDate(periodo.start) }}
                    </label>
                    <label class="bg-light border-0 text-center px-2 rounded"> 
                        <label class="d-block font-13 fst-italic">fim</label>
                        {{ toFormDate(periodo.end) }}
                    </label>
                </div>
                <div class="mt-3 bg-secondary p-2 rounded text-white fst-italic position-absolute w-100 start-0 bottom-0">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="font-13">
                            Proprietario:
                            <span class="font-12 d-block" :class="{ 'text-warning': periodo.owner.nome }" >{{ periodo.owner.nome ?? 'Sem proprietário' }}</span>
                        </div>
                    </div>    
                    <div class="d-flex align-items-center mt-3 justify-content-between">
                        <div class="font-13">
                            Situação:
                            <span :class="`font-12 badge rounded-fill text-bg-${statusClass} d-block`"></span>
                        </div>
                        <button v-if="periodo.status == 1" @click.stop="reserve" type="button" class="btn btn-primary btn-add-owner">
                            reservar
                        </button>
                        <div v-else class="font-13 text-end">
                            reservado:
                            <span class="font-12 d-block" >Marcelo Reis</span>
                        </div>
                    </div> 
                </div>
            </div>
    </div>
</template>
<script>
import { toFormDate } from '@/helpers/utils';
import ModelCotaPeriodo from '@/models/ModelCotaPeriodo';

export default {
    props: {
        periodo: {
            type: Object,
            required: true
        },
        onRemove: {
            type: Function
        },
        index: {
            type: Number
        },
        onClickEvent:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            swiped: false, 
            sync: false 
        }
    },
    methods:{
        swipe(){
            if(this.onClickEvent)
                this.swiped = !this.swiped;
        },
        reserve(){
            console.log('reservado sem propagação')
        },
        doRemove(){
            ModelCotaPeriodo.put(this.periodo.id, { idUsuario: '' }, this.onRemove(this.index) );
        },
        toFormDate(date){
            return toFormDate(date);
        }
    }, 
    computed: {
        statusClass(){
            switch(this.periodo.status){
                case '1': 
                    return 'success';
                case '0': 
                    return 'warning';
                default: 
                    return 'secondary';
            }
        },
    }
}
</script>
<style scoped>
    .cota-periodo.swipe-left-in{
        transform: translateX(-3.8rem);
    }
    .actions{
        background: #e13a3a;
    }
    .font-12{
        font-size: 12px;
    }
    .font-13{
        font-size: 13px;
    }
    .cota-periodo{
        padding-bottom: 8rem !important;
        transition: 0.15s ease-in-out all;
    }
    .btn-add-owner{
        --bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;
    }
</style>