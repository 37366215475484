<template>
    <div @click="$emit('showPeriods', cota)" class="p-1 mb-3 bg-primary text-white px-3 rounded pointer d-flex justify-content-between">
        {{ cota.title }}
        <span class="badge rounded-pill text-bg-light align-items-center">{{ disponiveis }}</span>    
    </div>
</template>
<script>
export default {
    name: 'CotaItem',
    emits: ['showPeriods'],
    props: {
        cota: Object
    },
    computed: {
        disponiveis(){
            return this.cota.period.filter( (i) => i.status == 1).length;
        }
    }
}
</script>
