import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/views/Home';
import CotistaList from '@/views/cotistas/List';
import CotistaForm from '@/views/cotistas/Form';

const routes = [
    { path: '/', component: Home },
    { path: '/cotistas', component: CotistaList },
    { path: '/cotista/:idUsuario', component: CotistaForm },
    { path: '/cotista', component: CotistaForm }
]
  
const router = createRouter({
    history: createWebHistory(),
    routes, 
});

export default router;
  