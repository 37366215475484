import http from '@/helpers/http';
import { events } from '@/helpers/emitter';

export default {
    put(id, params, callback){
        http.put(`cotaPeriodo.php?idCotaPeriodo=${id}`, params ).then( r => {
            events.$emit('toast.show', { msg: r.data.msg, type: r.data.success ? 'success' : 'danger' });


            if(callback && typeof callback == 'function')
                callback(r.data);
        });
    }
};