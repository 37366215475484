import http from '@/helpers/http';

export default {
    get(params){
        return http.get('usuarios.php', params );
    },
    proprietarios: {
        get(params){
            return http.get('proprietarios.php', params );
        }
    }
};