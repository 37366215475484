<template>
    <div @click="dismiss(configs.id)" :id="configs.id" :class="`toast pointer bg-gradient mb-3 align-items-center text-bg-${configs.type} border-0`" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
            <div class="toast-body">
                {{ msg }}
            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>
</template>
<script>
import Toast from 'bootstrap/js/dist/toast';
import { events } from '@/helpers/emitter';

export default {
    name: 'toast-item',
    data(){
        return {
            toastRef: null
        }
    },
    props: {
        configs: Object,
        msg: String
    },
    methods: {
        dismiss(id){
            events.$emit('toast.remove', id);
        }
    },
    mounted(){
        let el = document.getElementById(this.configs.id);
        
        this.toastRef = new Toast(el);

        el.addEventListener('hidden.bs.toast', () => {
            this.dismiss(this.configs.id);
        });

        this.toastRef.show();
    }

}
</script>

