<template>
    <div class="top-0 end-0 position-fixed p-3" style="z-index: 9999;">
        <toast-item v-for="toast in toasts" :key="toast.id" :configs="toast" :msg="toast.msg" />
    </div>
</template>
<script>
import ToastItem from '@/components/Toast';
import { events } from '@/helpers/emitter';

export default {
    name: 'ToastList',
    components: {
        ToastItem
    },
    data(){
        return {
            toasts: []
        }
    },
    methods: {
        addToast(args){
            if(args.msg){
                this.toasts.push({
                    id: Date.now(),
                    msg: args.msg,
                    type: args.type ?? 'primary'
                });
            }
        }
    },
    mounted(){
        events.$on('toast.show', e => {
            this.addToast(e);
        });

        events.$on('toast.remove', id => {
            this.toasts.filter( (obj, index) => {
                if(obj.id == id)
                    this.toasts.splice(index, 1);
            });
        });
    }
}
</script>