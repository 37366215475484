import { createApp } from 'vue'
import App from '@/App';
import Router from '@/router';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@/assets/animate.css';

createApp(App).use(Router).mount('#app')
