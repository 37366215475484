<template>
    <div class="row m-0">
      <div class="col-lg-9 col-xs-12 mt-5 order-lg-last">
        <div class="d-flex justify-content-center mt-4" style="flex-wrap: wrap;">
            <div class="position-relative d-flex align-items-center col-lg-10">
              <input v-model="pesquisa" :disabled="sync" placeholder="Digite o nome ou CPF" @keydown.enter="buscar" class="form-controle rounded-pill border-1 border p-2 ps-4 pe-5 w-100" />
              <button @click="buscar" class="position-absolute bg-primary border-0 rounded-circle right me-2">
                <i :class="{'text-white bi bi-search': !sync, 'text-warning spinner-border text-warnig spinner-border-sm': sync }"></i>
              </button>
            </div>
            <ul v-if="resultado.length > 0" id="list" class="col-lg-10">
              <li class="pointer" v-for="item in resultado" @click="modalCotas(item)" :key="item.oUsuario.idUsuarios">
                  <span>{{ item.oUsuario.nome }}</span>
                  <p>{{ item.oUsuario.cpf }}</p>
                  <p>{{ item.oCota.cota }}</p>
                  <p>{{ toStringDate(item.check_in) }} - {{ toStringDate(item.check_out) }}</p>
              </li>
            </ul>
          </div>
      </div>
      <div class="col-lg-3 xs-hidden order-lg-first">
        <cotas-component></cotas-component>
      </div>
    </div>
</template>
<script>
import CotasComponent from '@/components/CotasComponent'
import { events } from '@/helpers/emitter';
import http from '@/helpers/http';

export default {
  components: {
    CotasComponent
  },
  data() {
    return {
      pesquisa: null,
      resultado: [],
      sync: false
    }
  },
  methods: {
    buscar(){
      if(["", null, undefined].indexOf(this.pesquisa) !== -1){
        events.$emit('toast.show', { msg: 'insira um valor para poder pesquisar', type: 'warning' });

        return;
      }

      this.resultado = [];

      this.sync = true;

      http.get('usuarios.php', { buscar: this.pesquisa }).then(r => {
        if(r.data.length > 0)
          this.resultado = r.data;

        this.sync = false;  
      });      
    },
    modalCotas(cota){
      cota.title = cota.oCota.cota;

      this.resultado = [];

      this.pesquisa = null;

      events.$emit('ModalCotas.show', cota);
    },
    toStringDate(dateString){
      let date = new Date(dateString);

      return date.toLocaleDateString();
    }
  }
}
</script>
<style >
.pointer{
  cursor: pointer;
}

.right{
  right: 0;
}

#list {
  font-size: 12px;
  list-style: none;
  margin: 0;
  padding: 5px 0;
  background-color: white;
  border-radius: 0 0 5px 5px;
  border: 1px #ccc solid;
  border-top: none;
}

#list li {
  display: block;
  padding: 5px 15px;
}

#list li:hover {
  background-color: #ccc;
/*   color: white; */
}

#list li span, .selected span {
  font-weight: 550;
}

#list li p, .selected div {
  margin: 5px 0 0;
}
</style>