<template>
    <div class="row mt-5 mx-5">
        <div class="col-lg-8 offset-2 bg-light p-3 rounded">
            <div class="row">
                <div class="col-lg-6">
                    <label>Nome</label>
                    <input type="text" class="form-control" v-model="form.nome" maxlength="250" />    
                </div>
                <div class="col-lg-6">
                    <label class="fst-light">CPF</label>
                    <input type="text" class="form-control" v-model="form.cpf" maxlength="11" />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-6">
                    <label class="fst-light">Identidade</label>
                    <input type="text" class="form-control" v-model="form.rg" maxlength="15" />
                </div>
                <div class="col-lg-6">
                    <label class="fst-light">Telefone</label>
                    <input type="text" class="form-control" v-model="form.fone" maxlength="15" />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-12">
                    <label class="fst-light">Endereço</label>
                    <input type="text" class="form-control" v-model="form.endereco" maxlength="250" />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-12 text-end">
                    <button class="btn btn-primary" :disabled="sync" @click="salvar">
                        salvar 
                        <span class="spinner-grow spinner-grow-sm" role="status" v-if="sync" ></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/helpers/http';
import { events } from '@/helpers/emitter';

export default {
    data(){
        return {
            sync: false,
            form: {}
        }
    },
    methods: {
        emit(){
            events.$emit('toast.show', { msg: "mensagem", type: 'success' });
        },
        salvar(){
            this.sync = true;

            http.post("proprietarios.php", this.form ).then(r => {
                if(r.data.success)
                    setTimeout(() => this.$router.push('/cotistas'), 2000 );
                    
                events.$emit('toast.show', { msg: r.data.msg, type: r.data.success ? 'success' : 'danger' });    
                
                this.sync = false;
            });
        }
    },
    mounted(){
        if(this.$route.params.idUsuario){
            http.get("proprietarios.php", { params: { idUsuario: this.$route.params.idUsuario }}).then( r => this.form = r.data );
        }
    }
}
</script>

<style>

</style>