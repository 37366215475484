<template>
    <div class="text-end mt-3">
        <router-link class="btn btn-primary btn-sm end-0 mx-3" to="/cotista">
            <i class="bi bi-person-fill-add"></i> Cadastrar cotista
        </router-link>
    </div>
    <div class="row m-0 mt-3">
        <div v-for="user, index in proprietarios" :key="index" class="col-md-8 mb-3 rounded shadow-sm w-100 px-3 py-2 d-flex align-items-center justify-content-between">
            <div class="info">
                {{ user.nome }}
                <span class="d-block text-secondary fw-light fst-italic font-dot-eight">{{ user.cpf }}</span>
            </div>
            <div class="actions">
                <label class="pointer me-5" @click="showUserPeriods(user)">
                    <i class="bi text-primary bi-house-fill pointer mx-2"></i>

                    <div class="badge text-bg-primary rounded-pill">{{ user.periods.length ? user.periods.length : '' }}</div>
                </label>

                <router-link class="text-primary pointer mx-3" :to="`/cotista/${user.idUsuario}`"><i class="bi bi-pen-fill"></i></router-link>

                <i @click="confirmDelete(user.idUsuario)" class="bi bi-trash3 text-danger pointer mx-2"></i>
            </div>
        </div>
    </div>
    <div class="modal fade" ref="periodos">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{ selectedUser.nome }}
                        <small class="fw-light d-block fs-6">Cotas</small>
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body bg-light">
                    <div role="tablist" class="mb-3 w-100 rounded p-3 d-flex justify-content-between align-items-center fw-light fst-italic add-cota">
                        <a ref="tabList" class="active d-none" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" role="tab" aria-controls="home-tab-pane" aria-selected="true"></a>
                        Atribuir novas cotas ao usuário 
                        <i v-show="!tabListIsActive" @click="showTabList" class="bi bi-arrow-left fs-4 text-primary pointer"></i>
                        <i v-show="tabListIsActive" @click="tabListIsActive = false" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" role="tab" aria-controls="profile-tab-pane" aria-selected="false" class="bi bi-house-add-fill fs-4 text-primary pointer"></i>
                    </div>
                    
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel">
                            <CotaPeriodo v-for="cp, index in selectedUser.periods" :key="cp.id" :periodo="cp" :onRemove="removePeriod" :index="index" :onClickEvent="true" />
                        </div>
                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" >
                            <div class="rounded bg-white p-3 shadow-sm mb-4">
                                <select v-model="cotas.form.cota" class="form-select">
                                    <option value="" hidden >COTA</option>
                                    <option v-for="cota in cotas.list" :key="cota.title" :value="cota" >{{ cota.title }}</option>
                                </select>
                            </div>
                            <div class="rounded add-cota p-3">
                                <div v-show="periodo.owner.idUsuario == null" v-for="periodo, index in cotas.form.cota.period" :key="periodo.id" class="card text-bg-primary border-0 mb-3">
                                    <div class="card-body fw-light d-flex justify-content-between align-items-center text-white">
                                        {{ new Date(periodo.start).toLocaleDateString() }} a {{ new Date(periodo.end).toLocaleDateString() }}
                                        <i @click="addPeriod(index)" class="bi bi-plus-circle-fill pointer fs-5"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer"></div>
            </div>
        </div>
    </div>
    <div class="modal modal-sm fade" ref="cotas">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" >Adicionar cota</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body bg-light">
                    modal de cotas
                </div>
                <div class="modal-footer"></div>
            </div>
        </div>
    </div>
</template>
<script>

import Modal  from 'bootstrap/js/dist/modal';
import Tab from 'bootstrap/js/dist/tab';
import CotaPeriodo from '@/components/CotaPeriodo';
import ModelCotaProduto from '@/models/ModelCotaPeriodo';
import ModelCotas from '@/models/ModelCota';
import ModelUsuarios from '@/models/ModelUsuarios';

export default {
    components: { CotaPeriodo },
    data(){
        return {
            modalPeirods: null,
            modalCotas: null,
            tabListIsActive: true, 
            tabsList: null,
            proprietarios: [],
            cotas: {
                list: [], 
                form: {
                    cota: ""
                }
            },
            selectedUser: {},
            ListAnimateIn: null
        }
    },
    methods: {
        showTabList(){
            this.tabListIsActive = true; 
            
            this.tabsList.show()
        },
        showUserPeriods(user){
            this.selectedUser = user;

            this.showTabList();

            this.modalPeirods.show();
        },
        removePeriod(index){
            this.selectedUser.periods.splice(index, 1);
        },
        addPeriod(index){           
            this.cotas.form.cota.period[index].owner.idUsuario = this.selectedUser.idUsuario;

            if(!Array.isArray(this.selectedUser.periods))
                this.selectedUser.periods = [];
            
            this.selectedUser.periods.push(this.cotas.form.cota.period[index]);

            ModelCotaProduto.put(this.cotas.form.cota.period[index].id, { idUsuario: this.selectedUser.idUsuario });

            this.tabsList.show();
        },
        confirmDelete(id){
            console.log(id)
        }
    },
    mounted(){
        this.modalPeirods = new Modal(this.$refs['periodos']);
        
        this.modalCotas = new Modal(this.$refs['cotas']);

        this.tabsList = new Tab(this.$refs['tabList']);

        ModelUsuarios.proprietarios.get().then(r => this.proprietarios = r.data);

        ModelCotas.get().then(r => this.cotas.list = r.data);
    }
}
</script>

<style scoped>
    .font-dot-eight{
        font-size: .8rem;
    }
    .add-cota{
        background: #e9e9e9;
    }
</style>
